"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.argDocs = void 0;
var argDocs = exports.argDocs = {
  name: 'arg',
  category: 'Complex',
  syntax: ['arg(x)'],
  description: 'Compute the argument of a complex value. If x = a+bi, the argument is computed as atan2(b, a).',
  examples: ['arg(2 + 2i)', 'atan2(3, 2)', 'arg(2 + 3i)'],
  seealso: ['re', 'im', 'conj', 'abs']
};