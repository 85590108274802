"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dotDependencies = void 0;
var _dependenciesAddScalarGenerated = require("./dependenciesAddScalar.generated.js");
var _dependenciesConjGenerated = require("./dependenciesConj.generated.js");
var _dependenciesMultiplyScalarGenerated = require("./dependenciesMultiplyScalar.generated.js");
var _dependenciesSizeGenerated = require("./dependenciesSize.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var dotDependencies = exports.dotDependencies = {
  addScalarDependencies: _dependenciesAddScalarGenerated.addScalarDependencies,
  conjDependencies: _dependenciesConjGenerated.conjDependencies,
  multiplyScalarDependencies: _dependenciesMultiplyScalarGenerated.multiplyScalarDependencies,
  sizeDependencies: _dependenciesSizeGenerated.sizeDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createDot: _factoriesAny.createDot
};