"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasNumericValueDependencies = void 0;
var _dependenciesIsNumericGenerated = require("./dependenciesIsNumeric.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var hasNumericValueDependencies = exports.hasNumericValueDependencies = {
  isNumericDependencies: _dependenciesIsNumericGenerated.isNumericDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createHasNumericValue: _factoriesAny.createHasNumericValue
};