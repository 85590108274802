"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FunctionAssignmentNodeDependencies = void 0;
var _dependenciesNodeGenerated = require("./dependenciesNode.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var FunctionAssignmentNodeDependencies = exports.FunctionAssignmentNodeDependencies = {
  NodeDependencies: _dependenciesNodeGenerated.NodeDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createFunctionAssignmentNode: _factoriesAny.createFunctionAssignmentNode
};