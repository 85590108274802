"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.replacerDependencies = void 0;
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var replacerDependencies = exports.replacerDependencies = {
  createReplacer: _factoriesAny.createReplacer
};