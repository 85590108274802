"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResultSetDependencies = void 0;
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var ResultSetDependencies = exports.ResultSetDependencies = {
  createResultSet: _factoriesAny.createResultSet
};