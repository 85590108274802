"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.coshDependencies = void 0;
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var coshDependencies = exports.coshDependencies = {
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createCosh: _factoriesAny.createCosh
};