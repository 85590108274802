"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FractionDependencies = void 0;
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var FractionDependencies = exports.FractionDependencies = {
  createFractionClass: _factoriesAny.createFractionClass
};