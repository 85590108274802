"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.log2Docs = void 0;
var log2Docs = exports.log2Docs = {
  name: 'log2',
  category: 'Arithmetic',
  syntax: ['log2(x)'],
  description: 'Calculate the 2-base of a value. This is the same as calculating `log(x, 2)`.',
  examples: ['log2(0.03125)', 'log2(16)', 'log2(16) / log2(2)', 'pow(2, 4)'],
  seealso: ['exp', 'log1p', 'log', 'log10']
};