"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.catalanDependencies = void 0;
var _dependenciesAddScalarGenerated = require("./dependenciesAddScalar.generated.js");
var _dependenciesCombinationsGenerated = require("./dependenciesCombinations.generated.js");
var _dependenciesDivideScalarGenerated = require("./dependenciesDivideScalar.generated.js");
var _dependenciesIsIntegerGenerated = require("./dependenciesIsInteger.generated.js");
var _dependenciesIsNegativeGenerated = require("./dependenciesIsNegative.generated.js");
var _dependenciesMultiplyScalarGenerated = require("./dependenciesMultiplyScalar.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var catalanDependencies = exports.catalanDependencies = {
  addScalarDependencies: _dependenciesAddScalarGenerated.addScalarDependencies,
  combinationsDependencies: _dependenciesCombinationsGenerated.combinationsDependencies,
  divideScalarDependencies: _dependenciesDivideScalarGenerated.divideScalarDependencies,
  isIntegerDependencies: _dependenciesIsIntegerGenerated.isIntegerDependencies,
  isNegativeDependencies: _dependenciesIsNegativeGenerated.isNegativeDependencies,
  multiplyScalarDependencies: _dependenciesMultiplyScalarGenerated.multiplyScalarDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createCatalan: _factoriesAny.createCatalan
};