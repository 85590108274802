"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "createAbs", {
  enumerable: true,
  get: function get() {
    return _abs.createAbs;
  }
});
Object.defineProperty(exports, "createAccessorNode", {
  enumerable: true,
  get: function get() {
    return _AccessorNode.createAccessorNode;
  }
});
Object.defineProperty(exports, "createAcos", {
  enumerable: true,
  get: function get() {
    return _acos.createAcos;
  }
});
Object.defineProperty(exports, "createAcosh", {
  enumerable: true,
  get: function get() {
    return _acosh.createAcosh;
  }
});
Object.defineProperty(exports, "createAcot", {
  enumerable: true,
  get: function get() {
    return _acot.createAcot;
  }
});
Object.defineProperty(exports, "createAcoth", {
  enumerable: true,
  get: function get() {
    return _acoth.createAcoth;
  }
});
Object.defineProperty(exports, "createAcsc", {
  enumerable: true,
  get: function get() {
    return _acsc.createAcsc;
  }
});
Object.defineProperty(exports, "createAcsch", {
  enumerable: true,
  get: function get() {
    return _acsch.createAcsch;
  }
});
Object.defineProperty(exports, "createAdd", {
  enumerable: true,
  get: function get() {
    return _add.createAdd;
  }
});
Object.defineProperty(exports, "createAddScalar", {
  enumerable: true,
  get: function get() {
    return _addScalar.createAddScalar;
  }
});
Object.defineProperty(exports, "createAnd", {
  enumerable: true,
  get: function get() {
    return _and.createAnd;
  }
});
Object.defineProperty(exports, "createApply", {
  enumerable: true,
  get: function get() {
    return _apply.createApply;
  }
});
Object.defineProperty(exports, "createApplyTransform", {
  enumerable: true,
  get: function get() {
    return _applyTransform.createApplyTransform;
  }
});
Object.defineProperty(exports, "createArg", {
  enumerable: true,
  get: function get() {
    return _arg.createArg;
  }
});
Object.defineProperty(exports, "createArrayNode", {
  enumerable: true,
  get: function get() {
    return _ArrayNode.createArrayNode;
  }
});
Object.defineProperty(exports, "createAsec", {
  enumerable: true,
  get: function get() {
    return _asec.createAsec;
  }
});
Object.defineProperty(exports, "createAsech", {
  enumerable: true,
  get: function get() {
    return _asech.createAsech;
  }
});
Object.defineProperty(exports, "createAsin", {
  enumerable: true,
  get: function get() {
    return _asin.createAsin;
  }
});
Object.defineProperty(exports, "createAsinh", {
  enumerable: true,
  get: function get() {
    return _asinh.createAsinh;
  }
});
Object.defineProperty(exports, "createAssignmentNode", {
  enumerable: true,
  get: function get() {
    return _AssignmentNode.createAssignmentNode;
  }
});
Object.defineProperty(exports, "createAtan", {
  enumerable: true,
  get: function get() {
    return _atan.createAtan;
  }
});
Object.defineProperty(exports, "createAtan2", {
  enumerable: true,
  get: function get() {
    return _atan2.createAtan2;
  }
});
Object.defineProperty(exports, "createAtanh", {
  enumerable: true,
  get: function get() {
    return _atanh.createAtanh;
  }
});
Object.defineProperty(exports, "createAtomicMass", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createAtomicMass;
  }
});
Object.defineProperty(exports, "createAvogadro", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createAvogadro;
  }
});
Object.defineProperty(exports, "createBellNumbers", {
  enumerable: true,
  get: function get() {
    return _bellNumbers.createBellNumbers;
  }
});
Object.defineProperty(exports, "createBigNumberClass", {
  enumerable: true,
  get: function get() {
    return _BigNumber.createBigNumberClass;
  }
});
Object.defineProperty(exports, "createBignumber", {
  enumerable: true,
  get: function get() {
    return _bignumber.createBignumber;
  }
});
Object.defineProperty(exports, "createBin", {
  enumerable: true,
  get: function get() {
    return _bin.createBin;
  }
});
Object.defineProperty(exports, "createBitAnd", {
  enumerable: true,
  get: function get() {
    return _bitAnd.createBitAnd;
  }
});
Object.defineProperty(exports, "createBitNot", {
  enumerable: true,
  get: function get() {
    return _bitNot.createBitNot;
  }
});
Object.defineProperty(exports, "createBitOr", {
  enumerable: true,
  get: function get() {
    return _bitOr.createBitOr;
  }
});
Object.defineProperty(exports, "createBitXor", {
  enumerable: true,
  get: function get() {
    return _bitXor.createBitXor;
  }
});
Object.defineProperty(exports, "createBlockNode", {
  enumerable: true,
  get: function get() {
    return _BlockNode.createBlockNode;
  }
});
Object.defineProperty(exports, "createBohrMagneton", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createBohrMagneton;
  }
});
Object.defineProperty(exports, "createBohrRadius", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createBohrRadius;
  }
});
Object.defineProperty(exports, "createBoltzmann", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createBoltzmann;
  }
});
Object.defineProperty(exports, "createBoolean", {
  enumerable: true,
  get: function get() {
    return _boolean.createBoolean;
  }
});
Object.defineProperty(exports, "createCatalan", {
  enumerable: true,
  get: function get() {
    return _catalan.createCatalan;
  }
});
Object.defineProperty(exports, "createCbrt", {
  enumerable: true,
  get: function get() {
    return _cbrt.createCbrt;
  }
});
Object.defineProperty(exports, "createCeil", {
  enumerable: true,
  get: function get() {
    return _ceil.createCeil;
  }
});
Object.defineProperty(exports, "createChain", {
  enumerable: true,
  get: function get() {
    return _chain.createChain;
  }
});
Object.defineProperty(exports, "createChainClass", {
  enumerable: true,
  get: function get() {
    return _Chain.createChainClass;
  }
});
Object.defineProperty(exports, "createClassicalElectronRadius", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createClassicalElectronRadius;
  }
});
Object.defineProperty(exports, "createClone", {
  enumerable: true,
  get: function get() {
    return _clone.createClone;
  }
});
Object.defineProperty(exports, "createColumn", {
  enumerable: true,
  get: function get() {
    return _column.createColumn;
  }
});
Object.defineProperty(exports, "createColumnTransform", {
  enumerable: true,
  get: function get() {
    return _columnTransform.createColumnTransform;
  }
});
Object.defineProperty(exports, "createCombinations", {
  enumerable: true,
  get: function get() {
    return _combinations.createCombinations;
  }
});
Object.defineProperty(exports, "createCombinationsWithRep", {
  enumerable: true,
  get: function get() {
    return _combinationsWithRep.createCombinationsWithRep;
  }
});
Object.defineProperty(exports, "createCompare", {
  enumerable: true,
  get: function get() {
    return _compare.createCompare;
  }
});
Object.defineProperty(exports, "createCompareNatural", {
  enumerable: true,
  get: function get() {
    return _compareNatural.createCompareNatural;
  }
});
Object.defineProperty(exports, "createCompareText", {
  enumerable: true,
  get: function get() {
    return _compareText.createCompareText;
  }
});
Object.defineProperty(exports, "createCompile", {
  enumerable: true,
  get: function get() {
    return _compile.createCompile;
  }
});
Object.defineProperty(exports, "createComplex", {
  enumerable: true,
  get: function get() {
    return _complex.createComplex;
  }
});
Object.defineProperty(exports, "createComplexClass", {
  enumerable: true,
  get: function get() {
    return _Complex.createComplexClass;
  }
});
Object.defineProperty(exports, "createComposition", {
  enumerable: true,
  get: function get() {
    return _composition.createComposition;
  }
});
Object.defineProperty(exports, "createConcat", {
  enumerable: true,
  get: function get() {
    return _concat.createConcat;
  }
});
Object.defineProperty(exports, "createConcatTransform", {
  enumerable: true,
  get: function get() {
    return _concatTransform.createConcatTransform;
  }
});
Object.defineProperty(exports, "createConditionalNode", {
  enumerable: true,
  get: function get() {
    return _ConditionalNode.createConditionalNode;
  }
});
Object.defineProperty(exports, "createConductanceQuantum", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createConductanceQuantum;
  }
});
Object.defineProperty(exports, "createConj", {
  enumerable: true,
  get: function get() {
    return _conj.createConj;
  }
});
Object.defineProperty(exports, "createConstantNode", {
  enumerable: true,
  get: function get() {
    return _ConstantNode.createConstantNode;
  }
});
Object.defineProperty(exports, "createCorr", {
  enumerable: true,
  get: function get() {
    return _corr.createCorr;
  }
});
Object.defineProperty(exports, "createCos", {
  enumerable: true,
  get: function get() {
    return _cos.createCos;
  }
});
Object.defineProperty(exports, "createCosh", {
  enumerable: true,
  get: function get() {
    return _cosh.createCosh;
  }
});
Object.defineProperty(exports, "createCot", {
  enumerable: true,
  get: function get() {
    return _cot.createCot;
  }
});
Object.defineProperty(exports, "createCoth", {
  enumerable: true,
  get: function get() {
    return _coth.createCoth;
  }
});
Object.defineProperty(exports, "createCoulomb", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createCoulomb;
  }
});
Object.defineProperty(exports, "createCount", {
  enumerable: true,
  get: function get() {
    return _count.createCount;
  }
});
Object.defineProperty(exports, "createCreateUnit", {
  enumerable: true,
  get: function get() {
    return _createUnit.createCreateUnit;
  }
});
Object.defineProperty(exports, "createCross", {
  enumerable: true,
  get: function get() {
    return _cross.createCross;
  }
});
Object.defineProperty(exports, "createCsc", {
  enumerable: true,
  get: function get() {
    return _csc.createCsc;
  }
});
Object.defineProperty(exports, "createCsch", {
  enumerable: true,
  get: function get() {
    return _csch.createCsch;
  }
});
Object.defineProperty(exports, "createCtranspose", {
  enumerable: true,
  get: function get() {
    return _ctranspose.createCtranspose;
  }
});
Object.defineProperty(exports, "createCube", {
  enumerable: true,
  get: function get() {
    return _cube.createCube;
  }
});
Object.defineProperty(exports, "createCumSum", {
  enumerable: true,
  get: function get() {
    return _cumsum.createCumSum;
  }
});
Object.defineProperty(exports, "createCumSumTransform", {
  enumerable: true,
  get: function get() {
    return _cumsumTransform.createCumSumTransform;
  }
});
Object.defineProperty(exports, "createDeepEqual", {
  enumerable: true,
  get: function get() {
    return _deepEqual.createDeepEqual;
  }
});
Object.defineProperty(exports, "createDenseMatrixClass", {
  enumerable: true,
  get: function get() {
    return _DenseMatrix.createDenseMatrixClass;
  }
});
Object.defineProperty(exports, "createDerivative", {
  enumerable: true,
  get: function get() {
    return _derivative.createDerivative;
  }
});
Object.defineProperty(exports, "createDet", {
  enumerable: true,
  get: function get() {
    return _det.createDet;
  }
});
Object.defineProperty(exports, "createDeuteronMass", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createDeuteronMass;
  }
});
Object.defineProperty(exports, "createDiag", {
  enumerable: true,
  get: function get() {
    return _diag.createDiag;
  }
});
Object.defineProperty(exports, "createDiff", {
  enumerable: true,
  get: function get() {
    return _diff.createDiff;
  }
});
Object.defineProperty(exports, "createDiffTransform", {
  enumerable: true,
  get: function get() {
    return _diffTransform.createDiffTransform;
  }
});
Object.defineProperty(exports, "createDistance", {
  enumerable: true,
  get: function get() {
    return _distance.createDistance;
  }
});
Object.defineProperty(exports, "createDivide", {
  enumerable: true,
  get: function get() {
    return _divide.createDivide;
  }
});
Object.defineProperty(exports, "createDivideScalar", {
  enumerable: true,
  get: function get() {
    return _divideScalar.createDivideScalar;
  }
});
Object.defineProperty(exports, "createDot", {
  enumerable: true,
  get: function get() {
    return _dot.createDot;
  }
});
Object.defineProperty(exports, "createDotDivide", {
  enumerable: true,
  get: function get() {
    return _dotDivide.createDotDivide;
  }
});
Object.defineProperty(exports, "createDotMultiply", {
  enumerable: true,
  get: function get() {
    return _dotMultiply.createDotMultiply;
  }
});
Object.defineProperty(exports, "createDotPow", {
  enumerable: true,
  get: function get() {
    return _dotPow.createDotPow;
  }
});
Object.defineProperty(exports, "createE", {
  enumerable: true,
  get: function get() {
    return _constants.createE;
  }
});
Object.defineProperty(exports, "createEfimovFactor", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createEfimovFactor;
  }
});
Object.defineProperty(exports, "createEigs", {
  enumerable: true,
  get: function get() {
    return _eigs.createEigs;
  }
});
Object.defineProperty(exports, "createElectricConstant", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createElectricConstant;
  }
});
Object.defineProperty(exports, "createElectronMass", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createElectronMass;
  }
});
Object.defineProperty(exports, "createElementaryCharge", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createElementaryCharge;
  }
});
Object.defineProperty(exports, "createEqual", {
  enumerable: true,
  get: function get() {
    return _equal.createEqual;
  }
});
Object.defineProperty(exports, "createEqualScalar", {
  enumerable: true,
  get: function get() {
    return _equalScalar.createEqualScalar;
  }
});
Object.defineProperty(exports, "createEqualText", {
  enumerable: true,
  get: function get() {
    return _equalText.createEqualText;
  }
});
Object.defineProperty(exports, "createErf", {
  enumerable: true,
  get: function get() {
    return _erf.createErf;
  }
});
Object.defineProperty(exports, "createEvaluate", {
  enumerable: true,
  get: function get() {
    return _evaluate.createEvaluate;
  }
});
Object.defineProperty(exports, "createExp", {
  enumerable: true,
  get: function get() {
    return _exp.createExp;
  }
});
Object.defineProperty(exports, "createExpm", {
  enumerable: true,
  get: function get() {
    return _expm2.createExpm;
  }
});
Object.defineProperty(exports, "createExpm1", {
  enumerable: true,
  get: function get() {
    return _expm.createExpm1;
  }
});
Object.defineProperty(exports, "createFactorial", {
  enumerable: true,
  get: function get() {
    return _factorial.createFactorial;
  }
});
Object.defineProperty(exports, "createFalse", {
  enumerable: true,
  get: function get() {
    return _constants.createFalse;
  }
});
Object.defineProperty(exports, "createFaraday", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createFaraday;
  }
});
Object.defineProperty(exports, "createFermiCoupling", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createFermiCoupling;
  }
});
Object.defineProperty(exports, "createFft", {
  enumerable: true,
  get: function get() {
    return _fft.createFft;
  }
});
Object.defineProperty(exports, "createFibonacciHeapClass", {
  enumerable: true,
  get: function get() {
    return _FibonacciHeap.createFibonacciHeapClass;
  }
});
Object.defineProperty(exports, "createFilter", {
  enumerable: true,
  get: function get() {
    return _filter.createFilter;
  }
});
Object.defineProperty(exports, "createFilterTransform", {
  enumerable: true,
  get: function get() {
    return _filterTransform.createFilterTransform;
  }
});
Object.defineProperty(exports, "createFineStructure", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createFineStructure;
  }
});
Object.defineProperty(exports, "createFirstRadiation", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createFirstRadiation;
  }
});
Object.defineProperty(exports, "createFix", {
  enumerable: true,
  get: function get() {
    return _fix.createFix;
  }
});
Object.defineProperty(exports, "createFlatten", {
  enumerable: true,
  get: function get() {
    return _flatten.createFlatten;
  }
});
Object.defineProperty(exports, "createFloor", {
  enumerable: true,
  get: function get() {
    return _floor.createFloor;
  }
});
Object.defineProperty(exports, "createForEach", {
  enumerable: true,
  get: function get() {
    return _forEach.createForEach;
  }
});
Object.defineProperty(exports, "createForEachTransform", {
  enumerable: true,
  get: function get() {
    return _forEachTransform.createForEachTransform;
  }
});
Object.defineProperty(exports, "createFormat", {
  enumerable: true,
  get: function get() {
    return _format.createFormat;
  }
});
Object.defineProperty(exports, "createFraction", {
  enumerable: true,
  get: function get() {
    return _fraction.createFraction;
  }
});
Object.defineProperty(exports, "createFractionClass", {
  enumerable: true,
  get: function get() {
    return _Fraction.createFractionClass;
  }
});
Object.defineProperty(exports, "createFreqz", {
  enumerable: true,
  get: function get() {
    return _freqz.createFreqz;
  }
});
Object.defineProperty(exports, "createFunctionAssignmentNode", {
  enumerable: true,
  get: function get() {
    return _FunctionAssignmentNode.createFunctionAssignmentNode;
  }
});
Object.defineProperty(exports, "createFunctionNode", {
  enumerable: true,
  get: function get() {
    return _FunctionNode.createFunctionNode;
  }
});
Object.defineProperty(exports, "createGamma", {
  enumerable: true,
  get: function get() {
    return _gamma.createGamma;
  }
});
Object.defineProperty(exports, "createGasConstant", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createGasConstant;
  }
});
Object.defineProperty(exports, "createGcd", {
  enumerable: true,
  get: function get() {
    return _gcd.createGcd;
  }
});
Object.defineProperty(exports, "createGetMatrixDataType", {
  enumerable: true,
  get: function get() {
    return _getMatrixDataType.createGetMatrixDataType;
  }
});
Object.defineProperty(exports, "createGravitationConstant", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createGravitationConstant;
  }
});
Object.defineProperty(exports, "createGravity", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createGravity;
  }
});
Object.defineProperty(exports, "createHartreeEnergy", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createHartreeEnergy;
  }
});
Object.defineProperty(exports, "createHasNumericValue", {
  enumerable: true,
  get: function get() {
    return _hasNumericValue.createHasNumericValue;
  }
});
Object.defineProperty(exports, "createHelp", {
  enumerable: true,
  get: function get() {
    return _help.createHelp;
  }
});
Object.defineProperty(exports, "createHelpClass", {
  enumerable: true,
  get: function get() {
    return _Help.createHelpClass;
  }
});
Object.defineProperty(exports, "createHex", {
  enumerable: true,
  get: function get() {
    return _hex.createHex;
  }
});
Object.defineProperty(exports, "createHypot", {
  enumerable: true,
  get: function get() {
    return _hypot.createHypot;
  }
});
Object.defineProperty(exports, "createI", {
  enumerable: true,
  get: function get() {
    return _constants.createI;
  }
});
Object.defineProperty(exports, "createIdentity", {
  enumerable: true,
  get: function get() {
    return _identity.createIdentity;
  }
});
Object.defineProperty(exports, "createIfft", {
  enumerable: true,
  get: function get() {
    return _ifft.createIfft;
  }
});
Object.defineProperty(exports, "createIm", {
  enumerable: true,
  get: function get() {
    return _im.createIm;
  }
});
Object.defineProperty(exports, "createImmutableDenseMatrixClass", {
  enumerable: true,
  get: function get() {
    return _ImmutableDenseMatrix.createImmutableDenseMatrixClass;
  }
});
Object.defineProperty(exports, "createIndex", {
  enumerable: true,
  get: function get() {
    return _index.createIndex;
  }
});
Object.defineProperty(exports, "createIndexClass", {
  enumerable: true,
  get: function get() {
    return _MatrixIndex.createIndexClass;
  }
});
Object.defineProperty(exports, "createIndexNode", {
  enumerable: true,
  get: function get() {
    return _IndexNode.createIndexNode;
  }
});
Object.defineProperty(exports, "createIndexTransform", {
  enumerable: true,
  get: function get() {
    return _indexTransform.createIndexTransform;
  }
});
Object.defineProperty(exports, "createInfinity", {
  enumerable: true,
  get: function get() {
    return _constants.createInfinity;
  }
});
Object.defineProperty(exports, "createIntersect", {
  enumerable: true,
  get: function get() {
    return _intersect.createIntersect;
  }
});
Object.defineProperty(exports, "createInv", {
  enumerable: true,
  get: function get() {
    return _inv.createInv;
  }
});
Object.defineProperty(exports, "createInverseConductanceQuantum", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createInverseConductanceQuantum;
  }
});
Object.defineProperty(exports, "createInvmod", {
  enumerable: true,
  get: function get() {
    return _invmod.createInvmod;
  }
});
Object.defineProperty(exports, "createIsInteger", {
  enumerable: true,
  get: function get() {
    return _isInteger.createIsInteger;
  }
});
Object.defineProperty(exports, "createIsNaN", {
  enumerable: true,
  get: function get() {
    return _isNaN.createIsNaN;
  }
});
Object.defineProperty(exports, "createIsNegative", {
  enumerable: true,
  get: function get() {
    return _isNegative.createIsNegative;
  }
});
Object.defineProperty(exports, "createIsNumeric", {
  enumerable: true,
  get: function get() {
    return _isNumeric.createIsNumeric;
  }
});
Object.defineProperty(exports, "createIsPositive", {
  enumerable: true,
  get: function get() {
    return _isPositive.createIsPositive;
  }
});
Object.defineProperty(exports, "createIsPrime", {
  enumerable: true,
  get: function get() {
    return _isPrime.createIsPrime;
  }
});
Object.defineProperty(exports, "createIsZero", {
  enumerable: true,
  get: function get() {
    return _isZero.createIsZero;
  }
});
Object.defineProperty(exports, "createKldivergence", {
  enumerable: true,
  get: function get() {
    return _kldivergence.createKldivergence;
  }
});
Object.defineProperty(exports, "createKlitzing", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createKlitzing;
  }
});
Object.defineProperty(exports, "createKron", {
  enumerable: true,
  get: function get() {
    return _kron.createKron;
  }
});
Object.defineProperty(exports, "createLN10", {
  enumerable: true,
  get: function get() {
    return _constants.createLN10;
  }
});
Object.defineProperty(exports, "createLN2", {
  enumerable: true,
  get: function get() {
    return _constants.createLN2;
  }
});
Object.defineProperty(exports, "createLOG10E", {
  enumerable: true,
  get: function get() {
    return _constants.createLOG10E;
  }
});
Object.defineProperty(exports, "createLOG2E", {
  enumerable: true,
  get: function get() {
    return _constants.createLOG2E;
  }
});
Object.defineProperty(exports, "createLarger", {
  enumerable: true,
  get: function get() {
    return _larger.createLarger;
  }
});
Object.defineProperty(exports, "createLargerEq", {
  enumerable: true,
  get: function get() {
    return _largerEq.createLargerEq;
  }
});
Object.defineProperty(exports, "createLcm", {
  enumerable: true,
  get: function get() {
    return _lcm.createLcm;
  }
});
Object.defineProperty(exports, "createLeafCount", {
  enumerable: true,
  get: function get() {
    return _leafCount.createLeafCount;
  }
});
Object.defineProperty(exports, "createLeftShift", {
  enumerable: true,
  get: function get() {
    return _leftShift.createLeftShift;
  }
});
Object.defineProperty(exports, "createLgamma", {
  enumerable: true,
  get: function get() {
    return _lgamma.createLgamma;
  }
});
Object.defineProperty(exports, "createLog", {
  enumerable: true,
  get: function get() {
    return _log3.createLog;
  }
});
Object.defineProperty(exports, "createLog10", {
  enumerable: true,
  get: function get() {
    return _log.createLog10;
  }
});
Object.defineProperty(exports, "createLog1p", {
  enumerable: true,
  get: function get() {
    return _log1p.createLog1p;
  }
});
Object.defineProperty(exports, "createLog2", {
  enumerable: true,
  get: function get() {
    return _log2.createLog2;
  }
});
Object.defineProperty(exports, "createLoschmidt", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createLoschmidt;
  }
});
Object.defineProperty(exports, "createLsolve", {
  enumerable: true,
  get: function get() {
    return _lsolve.createLsolve;
  }
});
Object.defineProperty(exports, "createLsolveAll", {
  enumerable: true,
  get: function get() {
    return _lsolveAll.createLsolveAll;
  }
});
Object.defineProperty(exports, "createLup", {
  enumerable: true,
  get: function get() {
    return _lup.createLup;
  }
});
Object.defineProperty(exports, "createLusolve", {
  enumerable: true,
  get: function get() {
    return _lusolve.createLusolve;
  }
});
Object.defineProperty(exports, "createLyap", {
  enumerable: true,
  get: function get() {
    return _lyap.createLyap;
  }
});
Object.defineProperty(exports, "createMad", {
  enumerable: true,
  get: function get() {
    return _mad.createMad;
  }
});
Object.defineProperty(exports, "createMagneticConstant", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createMagneticConstant;
  }
});
Object.defineProperty(exports, "createMagneticFluxQuantum", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createMagneticFluxQuantum;
  }
});
Object.defineProperty(exports, "createMap", {
  enumerable: true,
  get: function get() {
    return _map.createMap;
  }
});
Object.defineProperty(exports, "createMapTransform", {
  enumerable: true,
  get: function get() {
    return _mapTransform.createMapTransform;
  }
});
Object.defineProperty(exports, "createMatrix", {
  enumerable: true,
  get: function get() {
    return _matrix.createMatrix;
  }
});
Object.defineProperty(exports, "createMatrixClass", {
  enumerable: true,
  get: function get() {
    return _Matrix.createMatrixClass;
  }
});
Object.defineProperty(exports, "createMatrixFromColumns", {
  enumerable: true,
  get: function get() {
    return _matrixFromColumns.createMatrixFromColumns;
  }
});
Object.defineProperty(exports, "createMatrixFromFunction", {
  enumerable: true,
  get: function get() {
    return _matrixFromFunction.createMatrixFromFunction;
  }
});
Object.defineProperty(exports, "createMatrixFromRows", {
  enumerable: true,
  get: function get() {
    return _matrixFromRows.createMatrixFromRows;
  }
});
Object.defineProperty(exports, "createMax", {
  enumerable: true,
  get: function get() {
    return _max.createMax;
  }
});
Object.defineProperty(exports, "createMaxTransform", {
  enumerable: true,
  get: function get() {
    return _maxTransform.createMaxTransform;
  }
});
Object.defineProperty(exports, "createMean", {
  enumerable: true,
  get: function get() {
    return _mean.createMean;
  }
});
Object.defineProperty(exports, "createMeanTransform", {
  enumerable: true,
  get: function get() {
    return _meanTransform.createMeanTransform;
  }
});
Object.defineProperty(exports, "createMedian", {
  enumerable: true,
  get: function get() {
    return _median.createMedian;
  }
});
Object.defineProperty(exports, "createMin", {
  enumerable: true,
  get: function get() {
    return _min.createMin;
  }
});
Object.defineProperty(exports, "createMinTransform", {
  enumerable: true,
  get: function get() {
    return _minTransform.createMinTransform;
  }
});
Object.defineProperty(exports, "createMod", {
  enumerable: true,
  get: function get() {
    return _mod.createMod;
  }
});
Object.defineProperty(exports, "createMode", {
  enumerable: true,
  get: function get() {
    return _mode.createMode;
  }
});
Object.defineProperty(exports, "createMolarMass", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createMolarMass;
  }
});
Object.defineProperty(exports, "createMolarMassC12", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createMolarMassC12;
  }
});
Object.defineProperty(exports, "createMolarPlanckConstant", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createMolarPlanckConstant;
  }
});
Object.defineProperty(exports, "createMolarVolume", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createMolarVolume;
  }
});
Object.defineProperty(exports, "createMultinomial", {
  enumerable: true,
  get: function get() {
    return _multinomial.createMultinomial;
  }
});
Object.defineProperty(exports, "createMultiply", {
  enumerable: true,
  get: function get() {
    return _multiply.createMultiply;
  }
});
Object.defineProperty(exports, "createMultiplyScalar", {
  enumerable: true,
  get: function get() {
    return _multiplyScalar.createMultiplyScalar;
  }
});
Object.defineProperty(exports, "createNaN", {
  enumerable: true,
  get: function get() {
    return _constants.createNaN;
  }
});
Object.defineProperty(exports, "createNeutronMass", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createNeutronMass;
  }
});
Object.defineProperty(exports, "createNode", {
  enumerable: true,
  get: function get() {
    return _Node.createNode;
  }
});
Object.defineProperty(exports, "createNorm", {
  enumerable: true,
  get: function get() {
    return _norm.createNorm;
  }
});
Object.defineProperty(exports, "createNot", {
  enumerable: true,
  get: function get() {
    return _not.createNot;
  }
});
Object.defineProperty(exports, "createNthRoot", {
  enumerable: true,
  get: function get() {
    return _nthRoot.createNthRoot;
  }
});
Object.defineProperty(exports, "createNthRoots", {
  enumerable: true,
  get: function get() {
    return _nthRoots.createNthRoots;
  }
});
Object.defineProperty(exports, "createNuclearMagneton", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createNuclearMagneton;
  }
});
Object.defineProperty(exports, "createNull", {
  enumerable: true,
  get: function get() {
    return _constants.createNull;
  }
});
Object.defineProperty(exports, "createNumber", {
  enumerable: true,
  get: function get() {
    return _number.createNumber;
  }
});
Object.defineProperty(exports, "createNumeric", {
  enumerable: true,
  get: function get() {
    return _numeric.createNumeric;
  }
});
Object.defineProperty(exports, "createObjectNode", {
  enumerable: true,
  get: function get() {
    return _ObjectNode.createObjectNode;
  }
});
Object.defineProperty(exports, "createOct", {
  enumerable: true,
  get: function get() {
    return _oct.createOct;
  }
});
Object.defineProperty(exports, "createOnes", {
  enumerable: true,
  get: function get() {
    return _ones.createOnes;
  }
});
Object.defineProperty(exports, "createOperatorNode", {
  enumerable: true,
  get: function get() {
    return _OperatorNode.createOperatorNode;
  }
});
Object.defineProperty(exports, "createOr", {
  enumerable: true,
  get: function get() {
    return _or.createOr;
  }
});
Object.defineProperty(exports, "createParenthesisNode", {
  enumerable: true,
  get: function get() {
    return _ParenthesisNode.createParenthesisNode;
  }
});
Object.defineProperty(exports, "createParse", {
  enumerable: true,
  get: function get() {
    return _parse.createParse;
  }
});
Object.defineProperty(exports, "createParser", {
  enumerable: true,
  get: function get() {
    return _parser.createParser;
  }
});
Object.defineProperty(exports, "createParserClass", {
  enumerable: true,
  get: function get() {
    return _Parser.createParserClass;
  }
});
Object.defineProperty(exports, "createPartitionSelect", {
  enumerable: true,
  get: function get() {
    return _partitionSelect.createPartitionSelect;
  }
});
Object.defineProperty(exports, "createPermutations", {
  enumerable: true,
  get: function get() {
    return _permutations.createPermutations;
  }
});
Object.defineProperty(exports, "createPhi", {
  enumerable: true,
  get: function get() {
    return _constants.createPhi;
  }
});
Object.defineProperty(exports, "createPi", {
  enumerable: true,
  get: function get() {
    return _constants.createPi;
  }
});
Object.defineProperty(exports, "createPickRandom", {
  enumerable: true,
  get: function get() {
    return _pickRandom.createPickRandom;
  }
});
Object.defineProperty(exports, "createPinv", {
  enumerable: true,
  get: function get() {
    return _pinv.createPinv;
  }
});
Object.defineProperty(exports, "createPlanckCharge", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createPlanckCharge;
  }
});
Object.defineProperty(exports, "createPlanckConstant", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createPlanckConstant;
  }
});
Object.defineProperty(exports, "createPlanckLength", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createPlanckLength;
  }
});
Object.defineProperty(exports, "createPlanckMass", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createPlanckMass;
  }
});
Object.defineProperty(exports, "createPlanckTemperature", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createPlanckTemperature;
  }
});
Object.defineProperty(exports, "createPlanckTime", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createPlanckTime;
  }
});
Object.defineProperty(exports, "createPolynomialRoot", {
  enumerable: true,
  get: function get() {
    return _polynomialRoot.createPolynomialRoot;
  }
});
Object.defineProperty(exports, "createPow", {
  enumerable: true,
  get: function get() {
    return _pow.createPow;
  }
});
Object.defineProperty(exports, "createPrint", {
  enumerable: true,
  get: function get() {
    return _print.createPrint;
  }
});
Object.defineProperty(exports, "createPrintTransform", {
  enumerable: true,
  get: function get() {
    return _printTransform.createPrintTransform;
  }
});
Object.defineProperty(exports, "createProd", {
  enumerable: true,
  get: function get() {
    return _prod.createProd;
  }
});
Object.defineProperty(exports, "createProtonMass", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createProtonMass;
  }
});
Object.defineProperty(exports, "createQr", {
  enumerable: true,
  get: function get() {
    return _qr.createQr;
  }
});
Object.defineProperty(exports, "createQuantileSeq", {
  enumerable: true,
  get: function get() {
    return _quantileSeq.createQuantileSeq;
  }
});
Object.defineProperty(exports, "createQuantileSeqTransform", {
  enumerable: true,
  get: function get() {
    return _quantileSeqTransform.createQuantileSeqTransform;
  }
});
Object.defineProperty(exports, "createQuantumOfCirculation", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createQuantumOfCirculation;
  }
});
Object.defineProperty(exports, "createRandom", {
  enumerable: true,
  get: function get() {
    return _random.createRandom;
  }
});
Object.defineProperty(exports, "createRandomInt", {
  enumerable: true,
  get: function get() {
    return _randomInt.createRandomInt;
  }
});
Object.defineProperty(exports, "createRange", {
  enumerable: true,
  get: function get() {
    return _range.createRange;
  }
});
Object.defineProperty(exports, "createRangeClass", {
  enumerable: true,
  get: function get() {
    return _Range.createRangeClass;
  }
});
Object.defineProperty(exports, "createRangeNode", {
  enumerable: true,
  get: function get() {
    return _RangeNode.createRangeNode;
  }
});
Object.defineProperty(exports, "createRangeTransform", {
  enumerable: true,
  get: function get() {
    return _rangeTransform.createRangeTransform;
  }
});
Object.defineProperty(exports, "createRationalize", {
  enumerable: true,
  get: function get() {
    return _rationalize.createRationalize;
  }
});
Object.defineProperty(exports, "createRe", {
  enumerable: true,
  get: function get() {
    return _re.createRe;
  }
});
Object.defineProperty(exports, "createReducedPlanckConstant", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createReducedPlanckConstant;
  }
});
Object.defineProperty(exports, "createRelationalNode", {
  enumerable: true,
  get: function get() {
    return _RelationalNode.createRelationalNode;
  }
});
Object.defineProperty(exports, "createReplacer", {
  enumerable: true,
  get: function get() {
    return _replacer.createReplacer;
  }
});
Object.defineProperty(exports, "createReshape", {
  enumerable: true,
  get: function get() {
    return _reshape.createReshape;
  }
});
Object.defineProperty(exports, "createResize", {
  enumerable: true,
  get: function get() {
    return _resize.createResize;
  }
});
Object.defineProperty(exports, "createResolve", {
  enumerable: true,
  get: function get() {
    return _resolve.createResolve;
  }
});
Object.defineProperty(exports, "createResultSet", {
  enumerable: true,
  get: function get() {
    return _ResultSet.createResultSet;
  }
});
Object.defineProperty(exports, "createReviver", {
  enumerable: true,
  get: function get() {
    return _reviver.createReviver;
  }
});
Object.defineProperty(exports, "createRightArithShift", {
  enumerable: true,
  get: function get() {
    return _rightArithShift.createRightArithShift;
  }
});
Object.defineProperty(exports, "createRightLogShift", {
  enumerable: true,
  get: function get() {
    return _rightLogShift.createRightLogShift;
  }
});
Object.defineProperty(exports, "createRotate", {
  enumerable: true,
  get: function get() {
    return _rotate.createRotate;
  }
});
Object.defineProperty(exports, "createRotationMatrix", {
  enumerable: true,
  get: function get() {
    return _rotationMatrix.createRotationMatrix;
  }
});
Object.defineProperty(exports, "createRound", {
  enumerable: true,
  get: function get() {
    return _round.createRound;
  }
});
Object.defineProperty(exports, "createRow", {
  enumerable: true,
  get: function get() {
    return _row.createRow;
  }
});
Object.defineProperty(exports, "createRowTransform", {
  enumerable: true,
  get: function get() {
    return _rowTransform.createRowTransform;
  }
});
Object.defineProperty(exports, "createRydberg", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createRydberg;
  }
});
Object.defineProperty(exports, "createSQRT1_2", {
  enumerable: true,
  get: function get() {
    return _constants.createSQRT1_2;
  }
});
Object.defineProperty(exports, "createSQRT2", {
  enumerable: true,
  get: function get() {
    return _constants.createSQRT2;
  }
});
Object.defineProperty(exports, "createSackurTetrode", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createSackurTetrode;
  }
});
Object.defineProperty(exports, "createSchur", {
  enumerable: true,
  get: function get() {
    return _schur.createSchur;
  }
});
Object.defineProperty(exports, "createSec", {
  enumerable: true,
  get: function get() {
    return _sec.createSec;
  }
});
Object.defineProperty(exports, "createSech", {
  enumerable: true,
  get: function get() {
    return _sech.createSech;
  }
});
Object.defineProperty(exports, "createSecondRadiation", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createSecondRadiation;
  }
});
Object.defineProperty(exports, "createSetCartesian", {
  enumerable: true,
  get: function get() {
    return _setCartesian.createSetCartesian;
  }
});
Object.defineProperty(exports, "createSetDifference", {
  enumerable: true,
  get: function get() {
    return _setDifference.createSetDifference;
  }
});
Object.defineProperty(exports, "createSetDistinct", {
  enumerable: true,
  get: function get() {
    return _setDistinct.createSetDistinct;
  }
});
Object.defineProperty(exports, "createSetIntersect", {
  enumerable: true,
  get: function get() {
    return _setIntersect.createSetIntersect;
  }
});
Object.defineProperty(exports, "createSetIsSubset", {
  enumerable: true,
  get: function get() {
    return _setIsSubset.createSetIsSubset;
  }
});
Object.defineProperty(exports, "createSetMultiplicity", {
  enumerable: true,
  get: function get() {
    return _setMultiplicity.createSetMultiplicity;
  }
});
Object.defineProperty(exports, "createSetPowerset", {
  enumerable: true,
  get: function get() {
    return _setPowerset.createSetPowerset;
  }
});
Object.defineProperty(exports, "createSetSize", {
  enumerable: true,
  get: function get() {
    return _setSize.createSetSize;
  }
});
Object.defineProperty(exports, "createSetSymDifference", {
  enumerable: true,
  get: function get() {
    return _setSymDifference.createSetSymDifference;
  }
});
Object.defineProperty(exports, "createSetUnion", {
  enumerable: true,
  get: function get() {
    return _setUnion.createSetUnion;
  }
});
Object.defineProperty(exports, "createSign", {
  enumerable: true,
  get: function get() {
    return _sign.createSign;
  }
});
Object.defineProperty(exports, "createSimplify", {
  enumerable: true,
  get: function get() {
    return _simplify.createSimplify;
  }
});
Object.defineProperty(exports, "createSimplifyConstant", {
  enumerable: true,
  get: function get() {
    return _simplifyConstant.createSimplifyConstant;
  }
});
Object.defineProperty(exports, "createSimplifyCore", {
  enumerable: true,
  get: function get() {
    return _simplifyCore.createSimplifyCore;
  }
});
Object.defineProperty(exports, "createSin", {
  enumerable: true,
  get: function get() {
    return _sin.createSin;
  }
});
Object.defineProperty(exports, "createSinh", {
  enumerable: true,
  get: function get() {
    return _sinh.createSinh;
  }
});
Object.defineProperty(exports, "createSize", {
  enumerable: true,
  get: function get() {
    return _size.createSize;
  }
});
Object.defineProperty(exports, "createSlu", {
  enumerable: true,
  get: function get() {
    return _slu.createSlu;
  }
});
Object.defineProperty(exports, "createSmaller", {
  enumerable: true,
  get: function get() {
    return _smaller.createSmaller;
  }
});
Object.defineProperty(exports, "createSmallerEq", {
  enumerable: true,
  get: function get() {
    return _smallerEq.createSmallerEq;
  }
});
Object.defineProperty(exports, "createSolveODE", {
  enumerable: true,
  get: function get() {
    return _solveODE.createSolveODE;
  }
});
Object.defineProperty(exports, "createSort", {
  enumerable: true,
  get: function get() {
    return _sort.createSort;
  }
});
Object.defineProperty(exports, "createSpaClass", {
  enumerable: true,
  get: function get() {
    return _Spa.createSpaClass;
  }
});
Object.defineProperty(exports, "createSparse", {
  enumerable: true,
  get: function get() {
    return _sparse.createSparse;
  }
});
Object.defineProperty(exports, "createSparseMatrixClass", {
  enumerable: true,
  get: function get() {
    return _SparseMatrix.createSparseMatrixClass;
  }
});
Object.defineProperty(exports, "createSpeedOfLight", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createSpeedOfLight;
  }
});
Object.defineProperty(exports, "createSplitUnit", {
  enumerable: true,
  get: function get() {
    return _splitUnit.createSplitUnit;
  }
});
Object.defineProperty(exports, "createSqrt", {
  enumerable: true,
  get: function get() {
    return _sqrt.createSqrt;
  }
});
Object.defineProperty(exports, "createSqrtm", {
  enumerable: true,
  get: function get() {
    return _sqrtm.createSqrtm;
  }
});
Object.defineProperty(exports, "createSquare", {
  enumerable: true,
  get: function get() {
    return _square.createSquare;
  }
});
Object.defineProperty(exports, "createSqueeze", {
  enumerable: true,
  get: function get() {
    return _squeeze.createSqueeze;
  }
});
Object.defineProperty(exports, "createStd", {
  enumerable: true,
  get: function get() {
    return _std.createStd;
  }
});
Object.defineProperty(exports, "createStdTransform", {
  enumerable: true,
  get: function get() {
    return _stdTransform.createStdTransform;
  }
});
Object.defineProperty(exports, "createStefanBoltzmann", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createStefanBoltzmann;
  }
});
Object.defineProperty(exports, "createStirlingS2", {
  enumerable: true,
  get: function get() {
    return _stirlingS.createStirlingS2;
  }
});
Object.defineProperty(exports, "createString", {
  enumerable: true,
  get: function get() {
    return _string.createString;
  }
});
Object.defineProperty(exports, "createSubset", {
  enumerable: true,
  get: function get() {
    return _subset.createSubset;
  }
});
Object.defineProperty(exports, "createSubsetTransform", {
  enumerable: true,
  get: function get() {
    return _subsetTransform.createSubsetTransform;
  }
});
Object.defineProperty(exports, "createSubtract", {
  enumerable: true,
  get: function get() {
    return _subtract.createSubtract;
  }
});
Object.defineProperty(exports, "createSubtractScalar", {
  enumerable: true,
  get: function get() {
    return _subtractScalar.createSubtractScalar;
  }
});
Object.defineProperty(exports, "createSum", {
  enumerable: true,
  get: function get() {
    return _sum.createSum;
  }
});
Object.defineProperty(exports, "createSumTransform", {
  enumerable: true,
  get: function get() {
    return _sumTransform.createSumTransform;
  }
});
Object.defineProperty(exports, "createSylvester", {
  enumerable: true,
  get: function get() {
    return _sylvester.createSylvester;
  }
});
Object.defineProperty(exports, "createSymbolNode", {
  enumerable: true,
  get: function get() {
    return _SymbolNode.createSymbolNode;
  }
});
Object.defineProperty(exports, "createSymbolicEqual", {
  enumerable: true,
  get: function get() {
    return _symbolicEqual.createSymbolicEqual;
  }
});
Object.defineProperty(exports, "createTan", {
  enumerable: true,
  get: function get() {
    return _tan.createTan;
  }
});
Object.defineProperty(exports, "createTanh", {
  enumerable: true,
  get: function get() {
    return _tanh.createTanh;
  }
});
Object.defineProperty(exports, "createTau", {
  enumerable: true,
  get: function get() {
    return _constants.createTau;
  }
});
Object.defineProperty(exports, "createThomsonCrossSection", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createThomsonCrossSection;
  }
});
Object.defineProperty(exports, "createTo", {
  enumerable: true,
  get: function get() {
    return _to.createTo;
  }
});
Object.defineProperty(exports, "createTrace", {
  enumerable: true,
  get: function get() {
    return _trace.createTrace;
  }
});
Object.defineProperty(exports, "createTranspose", {
  enumerable: true,
  get: function get() {
    return _transpose.createTranspose;
  }
});
Object.defineProperty(exports, "createTrue", {
  enumerable: true,
  get: function get() {
    return _constants.createTrue;
  }
});
Object.defineProperty(exports, "createTypeOf", {
  enumerable: true,
  get: function get() {
    return _typeOf.createTypeOf;
  }
});
Object.defineProperty(exports, "createTyped", {
  enumerable: true,
  get: function get() {
    return _typed.createTyped;
  }
});
Object.defineProperty(exports, "createUnaryMinus", {
  enumerable: true,
  get: function get() {
    return _unaryMinus.createUnaryMinus;
  }
});
Object.defineProperty(exports, "createUnaryPlus", {
  enumerable: true,
  get: function get() {
    return _unaryPlus.createUnaryPlus;
  }
});
Object.defineProperty(exports, "createUnequal", {
  enumerable: true,
  get: function get() {
    return _unequal.createUnequal;
  }
});
Object.defineProperty(exports, "createUnitClass", {
  enumerable: true,
  get: function get() {
    return _Unit.createUnitClass;
  }
});
Object.defineProperty(exports, "createUnitFunction", {
  enumerable: true,
  get: function get() {
    return _unit.createUnitFunction;
  }
});
Object.defineProperty(exports, "createUppercaseE", {
  enumerable: true,
  get: function get() {
    return _constants.createUppercaseE;
  }
});
Object.defineProperty(exports, "createUppercasePi", {
  enumerable: true,
  get: function get() {
    return _constants.createUppercasePi;
  }
});
Object.defineProperty(exports, "createUsolve", {
  enumerable: true,
  get: function get() {
    return _usolve.createUsolve;
  }
});
Object.defineProperty(exports, "createUsolveAll", {
  enumerable: true,
  get: function get() {
    return _usolveAll.createUsolveAll;
  }
});
Object.defineProperty(exports, "createVacuumImpedance", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createVacuumImpedance;
  }
});
Object.defineProperty(exports, "createVariance", {
  enumerable: true,
  get: function get() {
    return _variance.createVariance;
  }
});
Object.defineProperty(exports, "createVarianceTransform", {
  enumerable: true,
  get: function get() {
    return _varianceTransform.createVarianceTransform;
  }
});
Object.defineProperty(exports, "createVersion", {
  enumerable: true,
  get: function get() {
    return _constants.createVersion;
  }
});
Object.defineProperty(exports, "createWeakMixingAngle", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createWeakMixingAngle;
  }
});
Object.defineProperty(exports, "createWienDisplacement", {
  enumerable: true,
  get: function get() {
    return _physicalConstants.createWienDisplacement;
  }
});
Object.defineProperty(exports, "createXgcd", {
  enumerable: true,
  get: function get() {
    return _xgcd.createXgcd;
  }
});
Object.defineProperty(exports, "createXor", {
  enumerable: true,
  get: function get() {
    return _xor.createXor;
  }
});
Object.defineProperty(exports, "createZeros", {
  enumerable: true,
  get: function get() {
    return _zeros.createZeros;
  }
});
Object.defineProperty(exports, "createZeta", {
  enumerable: true,
  get: function get() {
    return _zeta.createZeta;
  }
});
Object.defineProperty(exports, "createZpk2tf", {
  enumerable: true,
  get: function get() {
    return _zpk2tf.createZpk2tf;
  }
});
var _typed = require("./core/function/typed.js");
var _ResultSet = require("./type/resultset/ResultSet.js");
var _BigNumber = require("./type/bignumber/BigNumber.js");
var _Complex = require("./type/complex/Complex.js");
var _Fraction = require("./type/fraction/Fraction.js");
var _Range = require("./type/matrix/Range.js");
var _Matrix = require("./type/matrix/Matrix.js");
var _DenseMatrix = require("./type/matrix/DenseMatrix.js");
var _clone = require("./function/utils/clone.js");
var _isInteger = require("./function/utils/isInteger.js");
var _isNegative = require("./function/utils/isNegative.js");
var _isNumeric = require("./function/utils/isNumeric.js");
var _hasNumericValue = require("./function/utils/hasNumericValue.js");
var _isPositive = require("./function/utils/isPositive.js");
var _isZero = require("./function/utils/isZero.js");
var _isNaN = require("./function/utils/isNaN.js");
var _typeOf = require("./function/utils/typeOf.js");
var _equalScalar = require("./function/relational/equalScalar.js");
var _SparseMatrix = require("./type/matrix/SparseMatrix.js");
var _number = require("./type/number.js");
var _string = require("./type/string.js");
var _boolean = require("./type/boolean.js");
var _bignumber = require("./type/bignumber/function/bignumber.js");
var _complex = require("./type/complex/function/complex.js");
var _fraction = require("./type/fraction/function/fraction.js");
var _matrix = require("./type/matrix/function/matrix.js");
var _matrixFromFunction = require("./function/matrix/matrixFromFunction.js");
var _matrixFromRows = require("./function/matrix/matrixFromRows.js");
var _matrixFromColumns = require("./function/matrix/matrixFromColumns.js");
var _splitUnit = require("./type/unit/function/splitUnit.js");
var _unaryMinus = require("./function/arithmetic/unaryMinus.js");
var _unaryPlus = require("./function/arithmetic/unaryPlus.js");
var _abs = require("./function/arithmetic/abs.js");
var _apply = require("./function/matrix/apply.js");
var _addScalar = require("./function/arithmetic/addScalar.js");
var _subtractScalar = require("./function/arithmetic/subtractScalar.js");
var _cbrt = require("./function/arithmetic/cbrt.js");
var _ceil = require("./function/arithmetic/ceil.js");
var _cube = require("./function/arithmetic/cube.js");
var _exp = require("./function/arithmetic/exp.js");
var _expm = require("./function/arithmetic/expm1.js");
var _fix = require("./function/arithmetic/fix.js");
var _floor = require("./function/arithmetic/floor.js");
var _gcd = require("./function/arithmetic/gcd.js");
var _lcm = require("./function/arithmetic/lcm.js");
var _log = require("./function/arithmetic/log10.js");
var _log2 = require("./function/arithmetic/log2.js");
var _mod = require("./function/arithmetic/mod.js");
var _multiplyScalar = require("./function/arithmetic/multiplyScalar.js");
var _multiply = require("./function/arithmetic/multiply.js");
var _nthRoot = require("./function/arithmetic/nthRoot.js");
var _sign = require("./function/arithmetic/sign.js");
var _sqrt = require("./function/arithmetic/sqrt.js");
var _square = require("./function/arithmetic/square.js");
var _subtract = require("./function/arithmetic/subtract.js");
var _xgcd = require("./function/arithmetic/xgcd.js");
var _invmod = require("./function/arithmetic/invmod.js");
var _dotMultiply = require("./function/arithmetic/dotMultiply.js");
var _bitAnd = require("./function/bitwise/bitAnd.js");
var _bitNot = require("./function/bitwise/bitNot.js");
var _bitOr = require("./function/bitwise/bitOr.js");
var _bitXor = require("./function/bitwise/bitXor.js");
var _arg = require("./function/complex/arg.js");
var _conj = require("./function/complex/conj.js");
var _im = require("./function/complex/im.js");
var _re = require("./function/complex/re.js");
var _not = require("./function/logical/not.js");
var _or = require("./function/logical/or.js");
var _xor = require("./function/logical/xor.js");
var _concat = require("./function/matrix/concat.js");
var _column = require("./function/matrix/column.js");
var _count = require("./function/matrix/count.js");
var _cross = require("./function/matrix/cross.js");
var _diag = require("./function/matrix/diag.js");
var _filter = require("./function/matrix/filter.js");
var _flatten = require("./function/matrix/flatten.js");
var _forEach = require("./function/matrix/forEach.js");
var _getMatrixDataType = require("./function/matrix/getMatrixDataType.js");
var _identity = require("./function/matrix/identity.js");
var _kron = require("./function/matrix/kron.js");
var _map = require("./function/matrix/map.js");
var _diff = require("./function/matrix/diff.js");
var _ones = require("./function/matrix/ones.js");
var _range = require("./function/matrix/range.js");
var _reshape = require("./function/matrix/reshape.js");
var _resize = require("./function/matrix/resize.js");
var _rotate = require("./function/matrix/rotate.js");
var _rotationMatrix = require("./function/matrix/rotationMatrix.js");
var _row = require("./function/matrix/row.js");
var _size = require("./function/matrix/size.js");
var _squeeze = require("./function/matrix/squeeze.js");
var _subset = require("./function/matrix/subset.js");
var _transpose = require("./function/matrix/transpose.js");
var _ctranspose = require("./function/matrix/ctranspose.js");
var _zeros = require("./function/matrix/zeros.js");
var _fft = require("./function/matrix/fft.js");
var _ifft = require("./function/matrix/ifft.js");
var _solveODE = require("./function/numeric/solveODE.js");
var _erf = require("./function/special/erf.js");
var _zeta = require("./function/special/zeta.js");
var _mode = require("./function/statistics/mode.js");
var _prod = require("./function/statistics/prod.js");
var _format = require("./function/string/format.js");
var _bin = require("./function/string/bin.js");
var _oct = require("./function/string/oct.js");
var _hex = require("./function/string/hex.js");
var _print = require("./function/string/print.js");
var _to = require("./function/unit/to.js");
var _isPrime = require("./function/utils/isPrime.js");
var _numeric = require("./function/utils/numeric.js");
var _divideScalar = require("./function/arithmetic/divideScalar.js");
var _pow = require("./function/arithmetic/pow.js");
var _round = require("./function/arithmetic/round.js");
var _log3 = require("./function/arithmetic/log.js");
var _log1p = require("./function/arithmetic/log1p.js");
var _nthRoots = require("./function/arithmetic/nthRoots.js");
var _dotPow = require("./function/arithmetic/dotPow.js");
var _dotDivide = require("./function/arithmetic/dotDivide.js");
var _lsolve = require("./function/algebra/solver/lsolve.js");
var _usolve = require("./function/algebra/solver/usolve.js");
var _lsolveAll = require("./function/algebra/solver/lsolveAll.js");
var _usolveAll = require("./function/algebra/solver/usolveAll.js");
var _leftShift = require("./function/bitwise/leftShift.js");
var _rightArithShift = require("./function/bitwise/rightArithShift.js");
var _rightLogShift = require("./function/bitwise/rightLogShift.js");
var _and = require("./function/logical/and.js");
var _compare = require("./function/relational/compare.js");
var _compareNatural = require("./function/relational/compareNatural.js");
var _compareText = require("./function/relational/compareText.js");
var _equal = require("./function/relational/equal.js");
var _equalText = require("./function/relational/equalText.js");
var _smaller = require("./function/relational/smaller.js");
var _smallerEq = require("./function/relational/smallerEq.js");
var _larger = require("./function/relational/larger.js");
var _largerEq = require("./function/relational/largerEq.js");
var _deepEqual = require("./function/relational/deepEqual.js");
var _unequal = require("./function/relational/unequal.js");
var _partitionSelect = require("./function/matrix/partitionSelect.js");
var _sort = require("./function/matrix/sort.js");
var _max = require("./function/statistics/max.js");
var _min = require("./function/statistics/min.js");
var _ImmutableDenseMatrix = require("./type/matrix/ImmutableDenseMatrix.js");
var _MatrixIndex = require("./type/matrix/MatrixIndex.js");
var _FibonacciHeap = require("./type/matrix/FibonacciHeap.js");
var _Spa = require("./type/matrix/Spa.js");
var _Unit = require("./type/unit/Unit.js");
var _unit = require("./type/unit/function/unit.js");
var _sparse = require("./type/matrix/function/sparse.js");
var _createUnit = require("./type/unit/function/createUnit.js");
var _acos = require("./function/trigonometry/acos.js");
var _acosh = require("./function/trigonometry/acosh.js");
var _acot = require("./function/trigonometry/acot.js");
var _acoth = require("./function/trigonometry/acoth.js");
var _acsc = require("./function/trigonometry/acsc.js");
var _acsch = require("./function/trigonometry/acsch.js");
var _asec = require("./function/trigonometry/asec.js");
var _asech = require("./function/trigonometry/asech.js");
var _asin = require("./function/trigonometry/asin.js");
var _asinh = require("./function/trigonometry/asinh.js");
var _atan = require("./function/trigonometry/atan.js");
var _atan2 = require("./function/trigonometry/atan2.js");
var _atanh = require("./function/trigonometry/atanh.js");
var _cos = require("./function/trigonometry/cos.js");
var _cosh = require("./function/trigonometry/cosh.js");
var _cot = require("./function/trigonometry/cot.js");
var _coth = require("./function/trigonometry/coth.js");
var _csc = require("./function/trigonometry/csc.js");
var _csch = require("./function/trigonometry/csch.js");
var _sec = require("./function/trigonometry/sec.js");
var _sech = require("./function/trigonometry/sech.js");
var _sin = require("./function/trigonometry/sin.js");
var _sinh = require("./function/trigonometry/sinh.js");
var _tan = require("./function/trigonometry/tan.js");
var _tanh = require("./function/trigonometry/tanh.js");
var _setCartesian = require("./function/set/setCartesian.js");
var _setDifference = require("./function/set/setDifference.js");
var _setDistinct = require("./function/set/setDistinct.js");
var _setIntersect = require("./function/set/setIntersect.js");
var _setIsSubset = require("./function/set/setIsSubset.js");
var _setMultiplicity = require("./function/set/setMultiplicity.js");
var _setPowerset = require("./function/set/setPowerset.js");
var _setSize = require("./function/set/setSize.js");
var _setSymDifference = require("./function/set/setSymDifference.js");
var _setUnion = require("./function/set/setUnion.js");
var _add = require("./function/arithmetic/add.js");
var _hypot = require("./function/arithmetic/hypot.js");
var _norm = require("./function/arithmetic/norm.js");
var _dot = require("./function/matrix/dot.js");
var _trace = require("./function/matrix/trace.js");
var _index = require("./type/matrix/function/index.js");
var _Node = require("./expression/node/Node.js");
var _AccessorNode = require("./expression/node/AccessorNode.js");
var _ArrayNode = require("./expression/node/ArrayNode.js");
var _AssignmentNode = require("./expression/node/AssignmentNode.js");
var _BlockNode = require("./expression/node/BlockNode.js");
var _ConditionalNode = require("./expression/node/ConditionalNode.js");
var _ConstantNode = require("./expression/node/ConstantNode.js");
var _FunctionAssignmentNode = require("./expression/node/FunctionAssignmentNode.js");
var _IndexNode = require("./expression/node/IndexNode.js");
var _ObjectNode = require("./expression/node/ObjectNode.js");
var _OperatorNode = require("./expression/node/OperatorNode.js");
var _ParenthesisNode = require("./expression/node/ParenthesisNode.js");
var _RangeNode = require("./expression/node/RangeNode.js");
var _RelationalNode = require("./expression/node/RelationalNode.js");
var _SymbolNode = require("./expression/node/SymbolNode.js");
var _FunctionNode = require("./expression/node/FunctionNode.js");
var _parse = require("./expression/parse.js");
var _compile = require("./expression/function/compile.js");
var _evaluate = require("./expression/function/evaluate.js");
var _Parser = require("./expression/Parser.js");
var _parser = require("./expression/function/parser.js");
var _lup = require("./function/algebra/decomposition/lup.js");
var _qr = require("./function/algebra/decomposition/qr.js");
var _slu = require("./function/algebra/decomposition/slu.js");
var _lusolve = require("./function/algebra/solver/lusolve.js");
var _polynomialRoot = require("./function/algebra/polynomialRoot.js");
var _Help = require("./expression/Help.js");
var _Chain = require("./type/chain/Chain.js");
var _help = require("./expression/function/help.js");
var _chain = require("./type/chain/function/chain.js");
var _det = require("./function/matrix/det.js");
var _inv = require("./function/matrix/inv.js");
var _pinv = require("./function/matrix/pinv.js");
var _eigs = require("./function/matrix/eigs.js");
var _expm2 = require("./function/matrix/expm.js");
var _sqrtm = require("./function/matrix/sqrtm.js");
var _sylvester = require("./function/algebra/sylvester.js");
var _schur = require("./function/algebra/decomposition/schur.js");
var _lyap = require("./function/algebra/lyap.js");
var _divide = require("./function/arithmetic/divide.js");
var _distance = require("./function/geometry/distance.js");
var _intersect = require("./function/geometry/intersect.js");
var _sum = require("./function/statistics/sum.js");
var _cumsum = require("./function/statistics/cumsum.js");
var _mean = require("./function/statistics/mean.js");
var _median = require("./function/statistics/median.js");
var _mad = require("./function/statistics/mad.js");
var _variance = require("./function/statistics/variance.js");
var _quantileSeq = require("./function/statistics/quantileSeq.js");
var _std = require("./function/statistics/std.js");
var _corr = require("./function/statistics/corr.js");
var _combinations = require("./function/probability/combinations.js");
var _combinationsWithRep = require("./function/probability/combinationsWithRep.js");
var _gamma = require("./function/probability/gamma.js");
var _lgamma = require("./function/probability/lgamma.js");
var _factorial = require("./function/probability/factorial.js");
var _kldivergence = require("./function/probability/kldivergence.js");
var _multinomial = require("./function/probability/multinomial.js");
var _permutations = require("./function/probability/permutations.js");
var _pickRandom = require("./function/probability/pickRandom.js");
var _random = require("./function/probability/random.js");
var _randomInt = require("./function/probability/randomInt.js");
var _stirlingS = require("./function/combinatorics/stirlingS2.js");
var _bellNumbers = require("./function/combinatorics/bellNumbers.js");
var _catalan = require("./function/combinatorics/catalan.js");
var _composition = require("./function/combinatorics/composition.js");
var _leafCount = require("./function/algebra/leafCount.js");
var _simplify = require("./function/algebra/simplify.js");
var _simplifyConstant = require("./function/algebra/simplifyConstant.js");
var _simplifyCore = require("./function/algebra/simplifyCore.js");
var _resolve = require("./function/algebra/resolve.js");
var _symbolicEqual = require("./function/algebra/symbolicEqual.js");
var _derivative = require("./function/algebra/derivative.js");
var _rationalize = require("./function/algebra/rationalize.js");
var _zpk2tf = require("./function/signal/zpk2tf.js");
var _freqz = require("./function/signal/freqz.js");
var _reviver = require("./json/reviver.js");
var _replacer = require("./json/replacer.js");
var _constants = require("./constants.js");
var _physicalConstants = require("./type/unit/physicalConstants.js");
var _applyTransform = require("./expression/transform/apply.transform.js");
var _columnTransform = require("./expression/transform/column.transform.js");
var _filterTransform = require("./expression/transform/filter.transform.js");
var _forEachTransform = require("./expression/transform/forEach.transform.js");
var _indexTransform = require("./expression/transform/index.transform.js");
var _mapTransform = require("./expression/transform/map.transform.js");
var _maxTransform = require("./expression/transform/max.transform.js");
var _meanTransform = require("./expression/transform/mean.transform.js");
var _minTransform = require("./expression/transform/min.transform.js");
var _rangeTransform = require("./expression/transform/range.transform.js");
var _rowTransform = require("./expression/transform/row.transform.js");
var _subsetTransform = require("./expression/transform/subset.transform.js");
var _concatTransform = require("./expression/transform/concat.transform.js");
var _diffTransform = require("./expression/transform/diff.transform.js");
var _stdTransform = require("./expression/transform/std.transform.js");
var _sumTransform = require("./expression/transform/sum.transform.js");
var _quantileSeqTransform = require("./expression/transform/quantileSeq.transform.js");
var _cumsumTransform = require("./expression/transform/cumsum.transform.js");
var _varianceTransform = require("./expression/transform/variance.transform.js");
var _printTransform = require("./expression/transform/print.transform.js");