"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NodeDependencies = void 0;
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var NodeDependencies = exports.NodeDependencies = {
  createNode: _factoriesAny.createNode
};