"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hypotDependencies = void 0;
var _dependenciesAbsGenerated = require("./dependenciesAbs.generated.js");
var _dependenciesAddScalarGenerated = require("./dependenciesAddScalar.generated.js");
var _dependenciesDivideScalarGenerated = require("./dependenciesDivideScalar.generated.js");
var _dependenciesIsPositiveGenerated = require("./dependenciesIsPositive.generated.js");
var _dependenciesMultiplyScalarGenerated = require("./dependenciesMultiplyScalar.generated.js");
var _dependenciesSmallerGenerated = require("./dependenciesSmaller.generated.js");
var _dependenciesSqrtGenerated = require("./dependenciesSqrt.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var hypotDependencies = exports.hypotDependencies = {
  absDependencies: _dependenciesAbsGenerated.absDependencies,
  addScalarDependencies: _dependenciesAddScalarGenerated.addScalarDependencies,
  divideScalarDependencies: _dependenciesDivideScalarGenerated.divideScalarDependencies,
  isPositiveDependencies: _dependenciesIsPositiveGenerated.isPositiveDependencies,
  multiplyScalarDependencies: _dependenciesMultiplyScalarGenerated.multiplyScalarDependencies,
  smallerDependencies: _dependenciesSmallerGenerated.smallerDependencies,
  sqrtDependencies: _dependenciesSqrtGenerated.sqrtDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createHypot: _factoriesAny.createHypot
};