"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.indexDependencies = void 0;
var _dependenciesIndexClassGenerated = require("./dependenciesIndexClass.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var indexDependencies = exports.indexDependencies = {
  IndexDependencies: _dependenciesIndexClassGenerated.IndexDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createIndex: _factoriesAny.createIndex
};