"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.floorDependencies = void 0;
var _dependenciesDenseMatrixClassGenerated = require("./dependenciesDenseMatrixClass.generated.js");
var _dependenciesEqualScalarGenerated = require("./dependenciesEqualScalar.generated.js");
var _dependenciesMatrixGenerated = require("./dependenciesMatrix.generated.js");
var _dependenciesRoundGenerated = require("./dependenciesRound.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _dependenciesZerosGenerated = require("./dependenciesZeros.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var floorDependencies = exports.floorDependencies = {
  DenseMatrixDependencies: _dependenciesDenseMatrixClassGenerated.DenseMatrixDependencies,
  equalScalarDependencies: _dependenciesEqualScalarGenerated.equalScalarDependencies,
  matrixDependencies: _dependenciesMatrixGenerated.matrixDependencies,
  roundDependencies: _dependenciesRoundGenerated.roundDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  zerosDependencies: _dependenciesZerosGenerated.zerosDependencies,
  createFloor: _factoriesAny.createFloor
};