"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chainDependencies = void 0;
var _dependenciesChainClassGenerated = require("./dependenciesChainClass.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var chainDependencies = exports.chainDependencies = {
  ChainDependencies: _dependenciesChainClassGenerated.ChainDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createChain: _factoriesAny.createChain
};