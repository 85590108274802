"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConditionalNodeDependencies = void 0;
var _dependenciesNodeGenerated = require("./dependenciesNode.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var ConditionalNodeDependencies = exports.ConditionalNodeDependencies = {
  NodeDependencies: _dependenciesNodeGenerated.NodeDependencies,
  createConditionalNode: _factoriesAny.createConditionalNode
};