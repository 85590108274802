"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.madDependencies = void 0;
var _dependenciesAbsGenerated = require("./dependenciesAbs.generated.js");
var _dependenciesMapGenerated = require("./dependenciesMap.generated.js");
var _dependenciesMedianGenerated = require("./dependenciesMedian.generated.js");
var _dependenciesSubtractGenerated = require("./dependenciesSubtract.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var madDependencies = exports.madDependencies = {
  absDependencies: _dependenciesAbsGenerated.absDependencies,
  mapDependencies: _dependenciesMapGenerated.mapDependencies,
  medianDependencies: _dependenciesMedianGenerated.medianDependencies,
  subtractDependencies: _dependenciesSubtractGenerated.subtractDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createMad: _factoriesAny.createMad
};