"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SQRT1_2Dependencies = void 0;
var _dependenciesBigNumberClassGenerated = require("./dependenciesBigNumberClass.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

// eslint-disable-line camelcase

var SQRT1_2Dependencies = exports.SQRT1_2Dependencies = {
  // eslint-disable-line camelcase
  BigNumberDependencies: _dependenciesBigNumberClassGenerated.BigNumberDependencies,
  createSQRT1_2: _factoriesAny.createSQRT1_2
};