"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RangeNodeDependencies = void 0;
var _dependenciesNodeGenerated = require("./dependenciesNode.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var RangeNodeDependencies = exports.RangeNodeDependencies = {
  NodeDependencies: _dependenciesNodeGenerated.NodeDependencies,
  createRangeNode: _factoriesAny.createRangeNode
};