"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zetaDocs = void 0;
var zetaDocs = exports.zetaDocs = {
  name: 'zeta',
  category: 'Special',
  syntax: ['zeta(s)'],
  description: 'Compute the Riemann Zeta Function using an infinite series and Riemanns Functional Equation for the entire complex plane',
  examples: ['zeta(0.2)', 'zeta(-0.5)', 'zeta(4)'],
  seealso: []
};