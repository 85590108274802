"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.falseDependencies = void 0;
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var falseDependencies = exports.falseDependencies = {
  createFalse: _factoriesAny.createFalse
};