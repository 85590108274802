"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parserDependencies = void 0;
var _dependenciesParserClassGenerated = require("./dependenciesParserClass.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var parserDependencies = exports.parserDependencies = {
  ParserDependencies: _dependenciesParserClassGenerated.ParserDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createParser: _factoriesAny.createParser
};