"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.complexDependencies = void 0;
var _dependenciesComplexClassGenerated = require("./dependenciesComplexClass.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var complexDependencies = exports.complexDependencies = {
  ComplexDependencies: _dependenciesComplexClassGenerated.ComplexDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createComplex: _factoriesAny.createComplex
};