"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LN2Dependencies = void 0;
var _dependenciesBigNumberClassGenerated = require("./dependenciesBigNumberClass.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var LN2Dependencies = exports.LN2Dependencies = {
  BigNumberDependencies: _dependenciesBigNumberClassGenerated.BigNumberDependencies,
  createLN2: _factoriesAny.createLN2
};