"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ComplexDependencies = void 0;
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var ComplexDependencies = exports.ComplexDependencies = {
  createComplexClass: _factoriesAny.createComplexClass
};