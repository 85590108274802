"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.acosDependencies = void 0;
var _dependenciesComplexClassGenerated = require("./dependenciesComplexClass.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var acosDependencies = exports.acosDependencies = {
  ComplexDependencies: _dependenciesComplexClassGenerated.ComplexDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createAcos: _factoriesAny.createAcos
};