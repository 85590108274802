"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.binDependencies = void 0;
var _dependenciesFormatGenerated = require("./dependenciesFormat.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var binDependencies = exports.binDependencies = {
  formatDependencies: _dependenciesFormatGenerated.formatDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createBin: _factoriesAny.createBin
};