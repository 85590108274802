"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.intersectDependencies = void 0;
var _dependenciesAbsGenerated = require("./dependenciesAbs.generated.js");
var _dependenciesAddGenerated = require("./dependenciesAdd.generated.js");
var _dependenciesAddScalarGenerated = require("./dependenciesAddScalar.generated.js");
var _dependenciesDivideScalarGenerated = require("./dependenciesDivideScalar.generated.js");
var _dependenciesEqualScalarGenerated = require("./dependenciesEqualScalar.generated.js");
var _dependenciesFlattenGenerated = require("./dependenciesFlatten.generated.js");
var _dependenciesIsNumericGenerated = require("./dependenciesIsNumeric.generated.js");
var _dependenciesIsZeroGenerated = require("./dependenciesIsZero.generated.js");
var _dependenciesMatrixGenerated = require("./dependenciesMatrix.generated.js");
var _dependenciesMultiplyGenerated = require("./dependenciesMultiply.generated.js");
var _dependenciesMultiplyScalarGenerated = require("./dependenciesMultiplyScalar.generated.js");
var _dependenciesSmallerGenerated = require("./dependenciesSmaller.generated.js");
var _dependenciesSubtractGenerated = require("./dependenciesSubtract.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var intersectDependencies = exports.intersectDependencies = {
  absDependencies: _dependenciesAbsGenerated.absDependencies,
  addDependencies: _dependenciesAddGenerated.addDependencies,
  addScalarDependencies: _dependenciesAddScalarGenerated.addScalarDependencies,
  divideScalarDependencies: _dependenciesDivideScalarGenerated.divideScalarDependencies,
  equalScalarDependencies: _dependenciesEqualScalarGenerated.equalScalarDependencies,
  flattenDependencies: _dependenciesFlattenGenerated.flattenDependencies,
  isNumericDependencies: _dependenciesIsNumericGenerated.isNumericDependencies,
  isZeroDependencies: _dependenciesIsZeroGenerated.isZeroDependencies,
  matrixDependencies: _dependenciesMatrixGenerated.matrixDependencies,
  multiplyDependencies: _dependenciesMultiplyGenerated.multiplyDependencies,
  multiplyScalarDependencies: _dependenciesMultiplyScalarGenerated.multiplyScalarDependencies,
  smallerDependencies: _dependenciesSmallerGenerated.smallerDependencies,
  subtractDependencies: _dependenciesSubtractGenerated.subtractDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createIntersect: _factoriesAny.createIntersect
};