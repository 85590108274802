"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.versionDependencies = void 0;
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var versionDependencies = exports.versionDependencies = {
  createVersion: _factoriesAny.createVersion
};