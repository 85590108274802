"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOG2EDependencies = void 0;
var _dependenciesBigNumberClassGenerated = require("./dependenciesBigNumberClass.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var LOG2EDependencies = exports.LOG2EDependencies = {
  BigNumberDependencies: _dependenciesBigNumberClassGenerated.BigNumberDependencies,
  createLOG2E: _factoriesAny.createLOG2E
};