"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matrixFromFunctionDependencies = void 0;
var _dependenciesIsZeroGenerated = require("./dependenciesIsZero.generated.js");
var _dependenciesMatrixGenerated = require("./dependenciesMatrix.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var matrixFromFunctionDependencies = exports.matrixFromFunctionDependencies = {
  isZeroDependencies: _dependenciesIsZeroGenerated.isZeroDependencies,
  matrixDependencies: _dependenciesMatrixGenerated.matrixDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createMatrixFromFunction: _factoriesAny.createMatrixFromFunction
};