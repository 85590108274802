"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numericDependencies = void 0;
var _dependenciesBignumberGenerated = require("./dependenciesBignumber.generated.js");
var _dependenciesFractionGenerated = require("./dependenciesFraction.generated.js");
var _dependenciesNumberGenerated = require("./dependenciesNumber.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var numericDependencies = exports.numericDependencies = {
  bignumberDependencies: _dependenciesBignumberGenerated.bignumberDependencies,
  fractionDependencies: _dependenciesFractionGenerated.fractionDependencies,
  numberDependencies: _dependenciesNumberGenerated.numberDependencies,
  createNumeric: _factoriesAny.createNumeric
};