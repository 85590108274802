"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sparseDependencies = void 0;
var _dependenciesSparseMatrixClassGenerated = require("./dependenciesSparseMatrixClass.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var sparseDependencies = exports.sparseDependencies = {
  SparseMatrixDependencies: _dependenciesSparseMatrixClassGenerated.SparseMatrixDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createSparse: _factoriesAny.createSparse
};