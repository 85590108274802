"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ifftDependencies = void 0;
var _dependenciesConjGenerated = require("./dependenciesConj.generated.js");
var _dependenciesDotDivideGenerated = require("./dependenciesDotDivide.generated.js");
var _dependenciesFftGenerated = require("./dependenciesFft.generated.js");
var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");
var _factoriesAny = require("../../factoriesAny.js");
/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */

var ifftDependencies = exports.ifftDependencies = {
  conjDependencies: _dependenciesConjGenerated.conjDependencies,
  dotDivideDependencies: _dependenciesDotDivideGenerated.dotDivideDependencies,
  fftDependencies: _dependenciesFftGenerated.fftDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createIfft: _factoriesAny.createIfft
};